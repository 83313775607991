@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/*----------------> Responsive Section <-----------------*/
@media only screen and (max-width: 1200px) {
  .layouts {
    padding: 0px 0px 30px 22vw;
    background: #ffffff;
    min-height: 100vh;
    width: 100vw;
  }
}

.pagination button.active {
  font-weight: 500;
  color: white;
  background-color: #002e8c;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
