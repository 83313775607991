.btn--primary {
  background: #002e8c;
  border: 1px solid #ffffff;
  border-radius: 4px;
  /* width: auto; */
  min-height: 38px;
  color: white;
}
.btn--disabled {
  background: rgb(173, 171, 171);
  border: 1px solid #ffffff;
  border-radius: 4px;
  /* width: auto; */
  min-height: 38px;
  color: white;
}
.btn--disabled:hover {
  background: rgb(173, 171, 171);
  color: white;
}
.btn--secondary {
  background: #99a7c1;
  border-radius: 4px;
  /* width: 100%; */
  min-height: 38px;
  color: white;
}
.btn--primary:hover {
  background: #0094d0;
  color: white;
}
.btn--secondary:hover {
  background: #8aa4d4;
  color: white;
  /* overflow: ; */
}
.btn--primary--outline {
  /* width: 100%; */
  background: #ffffff;
  border: 1px solid #89a7e0;
  border-radius: 2px;
  /* min-height: 26px; */
  padding: 4px;
  color: #2e5aac;
  font-weight: 600;
}
.btn--primary--outline:hover {
  background: #8aa4d4;
  color: white;
}
/* .btn-- {
  background: #ffffff;
  border: 2px solid #3b6eb5;
  border-radius: 4px;
  color: #3b6eb5;
  width: auto;
  height: auto;
  padding: 0px 48px 0px 48px;
}
.btn--:hover {
  background: #3b6eb5;
  color: white;
} */
